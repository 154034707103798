.fail-popup {
  position: sticky;
  top: 0;
  z-index: 99;
  text-align: center;
  background-color: #cc0000;
  padding: 10px;
}

.fail-popup:hover {
  cursor: pointer;
}
