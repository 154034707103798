.time-picker-container {
  position: relative;
}

#time-picker-container.focus {
  border: 1px solid rgba(var(--bs-primary-rgb), 0.7) !important;
}

.time-picker-container {
  position: relative;
}

.time-picker {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.time-picker:hover {
  cursor: pointer;
}

.time-picker-dropdown {
  position: absolute;
  height: 110px;
  width: 100%;
  background-color: #191b1d;
  border: 1px solid rgba(var(--bs-primary-rgb), 0.7);
  border-radius: 8px;
  left: 0;
  top: 63px;
  z-index: 90;
  display: flex;
  flex-direction: column;
}

.time-picker-set {
  border-top: 1px solid rgba(var(--bs-primary-rgb), 0.7);
  height: 30px;
  display: flex;
  justify-content: space-between;
}

.time-picker-set > p {
  width: 100%;
  text-align: center;
}

#clear,
#set {
  color: #17a2b8;
}

.time-picker-options {
  display: flex;
  height: 85px;
  overflow-y: auto;
}

.time-picker-options > div {
  display: flex;
  height: 100%;
  overflow-y: auto;
  flex-direction: column;
  padding: 5px;
  width: 50%;
  overflow-y: auto;
}

/* .time-picker-display {
  position: absolute;
  height: 85px;
  width: 100%;
  position: absolute;
  bottom: 0;
} */

.time-picker-options > div > p {
  text-align: center;
}

.time-picker-options > div > p.active {
  color: black;
  background-color: rgba(var(--bs-primary-rgb), 0.7);
  border-radius: 2px;
}
