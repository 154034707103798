.confirm-back {
    text-align: center;
}

.confirm-back:hover {
    cursor: pointer;
}

.confirm-back:hover > p {
    color: white;
}
